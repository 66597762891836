export const state = () => ({
    meta: {
        title: null,
        description: '',
    },
    loaded: {
        user: false,
        serviceTypes: false
    },
    logged: false,
    settings: {},
    setUserLoaded: false,
    Loaded: false,
    service_types: [],
    user: {
        id: null,
        first_name: '',
        last_name: '',
        full_name: '',
        email: '',
        phone: '',
        image: null,
    },
    position: null,
    active_bookings_count: 0,
    languages: [
        // {name: 'Romana', code: 'ro'},
        {name: 'English', code: 'en'}
    ],
    stateResource: {},
})

export const mutations = {
    setMeta: (state, payload) => {
        state.meta.title = payload.title ? payload.title : null;
        state.meta.description = payload.description ? payload.description : '';
    },
    setUserLoaded: (state, user) => {
        state.setUserLoaded = true;
    },
    setUser: (state, user) => {
        state.logged = true;
        state.user.id = user.id;
        state.user.first_name = user.first_name;
        state.user.last_name = user.last_name;
        state.user.full_name = (user.first_name ? user.first_name : '') + ' ' + (user.last_name ? user.last_name : '');
        state.user.email = user.email;
        state.user.phone = user.phone;
        state.user.image = user.image;
        state.user.has_business = user.has_business;
        state.setUserLoaded = true;
        // bugsnagClient.user = state.user;
    },
    logout: (state) => {
        state.logged = false;
        state.user = {
            id: null,
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
            has_business: false,
        };
        // VueInstance.$auth.destroyToken();
    },
    setResource: (state, payload) => {
        state.stateResource  = {...state.stateResource, [payload[0]]: payload[1] };
    },
    deleteResource: (state, resource) => {
        state.stateResource[resource] = [];
    },
    setLanguages: (state, payload) => {
        state.languages = payload;
    },
    setActiveBookingsCount: (state, payload) => {
        state.active_bookings_count = payload;
    },
    setSettings: (state, payload) => {
        state.settings = payload;
        if(payload.languages) {
            let languages = JSON.parse(payload.languages);
            state.languages = state.languages.filter(function (lang) {
                return languages.indexOf(lang.code) > -1;
            });
        }
    },
    setServiceTypes: (state, values) => {
        state.service_types = values
    },
    setPosition: (state, value) => {
        state.position = value
    },
    setLoaded: (state, value) => {
        state.loaded[value] = true
    },
}


