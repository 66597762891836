//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import {mapState} from 'vuex';
import { initMixin } from '@/mixins/initMixin'
// import {FadeTransition} from 'vue2-transitions'

export default {
    name: 'layoutMobile',
    mixins: [initMixin],
    // components: { FadeTransition },
    data: function () {
        return {

        }
    },
    computed: {
        ...mapState({
            user: state => state.user,
            meta: state => state.meta,
            settings: state => state.settings,
            active_bookings_count: state => state.active_bookings_count,
        })
    },
    methods: {
        getClass (tab) {
            switch (tab) {
                case 'search': {
                    if (['home', 'search', 'business.details', 'booking'].indexOf(this.$route.name) > -1) {
                        return 'active'
                    }
                    break;
                }
                case 'account': {
                    if (this.$route.name.match( /(account|page|contact-us)/ ) ) {
                        return 'active'
                    }
                    if (['account', 'page', 'contact-us'].indexOf(this.$route.name) > -1) {
                        return 'active'
                    }
                    break;
                }
            }
        },
        setCrispUser () {
            $crisp.push(["set", "user:email", [this.user.email]])
            $crisp.push(["set", "user:nickname", [this.user.first_name + ' ' + this.user.last_name]]);
        },
        loadCrisp () {
            if (window.CRISP_WEBSITE_ID) {
                return;
            }
            window.$crisp=[];
            window.CRISP_WEBSITE_ID="f9d25d97-bd9a-43c7-baf7-a3be1d440ba4";
            (function(){
                let d=document;
                let s=d.createElement("script");
                s.src="https://client.crisp.chat/l.js";
                s.async=1;
                d.getElementsByTagName("head")[0].appendChild(s);
            })();
            this.setCrispUser()
        },
    },
    mounted () {
        this.loadCrisp()
        // this.$OneSignal.push(() => {
        //     this.$OneSignal.isPushNotificationsEnabled((isEnabled) => {
        //         if (isEnabled) {
        //             console.log('Push notifications are enabled!')
        //         } else {
        //             console.log('Push notifications are not enabled yet.')
        //         }
        //     })
        // })
    },
}
