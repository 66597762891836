//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { mapState } from 'vuex'
import { metaMixin } from '@/mixins/metaMixin'

export default {
    name: 'BusinessDetailsMobile',
    mixins: [metaMixin],
    data: function () {
        return {
            loading: {
                data: false,
            },
            business: {
                images: [],
                description: ''
            },
        }
    },
    computed: {
        ...mapState({
            position: (state) => state.position,
        }),
    },
    mounted() {
        if (this.$fetchState.timestamp <= Date.now() - 30000) {
            this.$fetch()
        }
    },
    async fetch() {
        await this.getData();
    },
    methods: {
        getData () {
           this.loading.data = true;
            this.$axios.post("/businesses/details", {businessSlug: this.$route.params.businessSlug})
                .then((res) =>{
                    this.business = res.business;
                    this.loading.data = false;
                    this.meta.title = `${this.business.name} - ESSE`
                    // this.meta.description = res.data.info.metadescription
                })
                .catch(()=> {
                    this.loading.data = false;
                });
        },
        updateFavorite() {
            this.business.isFavorite = !this.business.isFavorite
            this.$axios.post("/businesses/favorite/update", {id: this.business.id, isFavorite: this.business.isFavorite});
        },
        getLocationUrl () {
            if(this.business.latitude) {
                let coords = encodeURIComponent(`${this.business.latitude},${this.business.longitude}`)
                return `https://www.google.com/maps/search/?api=1&query=${coords}`
            } else {
                return `https://www.google.com/maps/search/?api=1&query=${this.business.address}`
            }
        },
    }
}
