import { render, staticRenderFns } from "./BookingMobile.vue?vue&type=template&id=23cc6866&"
import script from "./BookingMobile.vue?vue&type=script&lang=js&"
export * from "./BookingMobile.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CalendarPicker: require('/Users/tudorserban/Projects/esse-fe/components/CalendarPicker.vue').default,FormInput: require('/Users/tudorserban/Projects/esse-fe/components/FormInput.vue').default,FormCheckbox: require('/Users/tudorserban/Projects/esse-fe/components/FormCheckbox.vue').default,Btn: require('/Users/tudorserban/Projects/esse-fe/components/Btn.vue').default,FormGroup: require('/Users/tudorserban/Projects/esse-fe/components/FormGroup.vue').default,Stars: require('/Users/tudorserban/Projects/esse-fe/components/Stars.vue').default,FormTextarea: require('/Users/tudorserban/Projects/esse-fe/components/FormTextarea.vue').default})
