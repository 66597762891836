import { minLength, required } from 'vuelidate/lib/validators'

export const passwordValidators = {
    required,
    minLength: minLength(6),
    containsUppercase: (value) => /[A-Z]/.test(value),
    containsLowercase: (value) => /[a-z]/.test(value),
    containsNumber: (value) => /[0-9]/.test(value),
    containsSpecial: (value) => /[#?!@$%^&*-]/.test(value),
}
