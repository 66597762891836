//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { mapState } from 'vuex'
import { metaMixin } from '@/mixins/metaMixin'

export default {
    name: 'ExploreMobile',
    mixins: [metaMixin],
    data: function () {
        return {
            loading: {
                types: false,
                categories: false,
                services: false,
            },
            filters: {
                term: '',
            },
            selectedServiceType: null,
            selectedServiceCategory: null,
            serviceCategoriesQuery: '',
            servicesQuery: '',
        }
    },
    computed: {
        ...mapState({
            user: state => state.user,
            service_types: state => state.service_types
        }),
        filteredServiceCategories() {
            if (!this.selectedServiceType) {
                return [];
            }
            if (this.serviceCategoriesQuery.length === 0) {
                return this.selectedServiceType.service_categories;
            }
            const term = this.serviceCategoriesQuery.toLowerCase()

            return this.selectedServiceType.service_categories.filter(item => item.name.toLowerCase().indexOf(term) > -1)
        },
        filteredServices() {
            if (!this.selectedServiceCategory) {
                return [];
            }
            if (this.servicesQuery.length === 0) {
                return this.selectedServiceCategory.services;
            }
            const term = this.servicesQuery.toLowerCase()

            return this.selectedServiceCategory.services.filter(item => item.name.toLowerCase().indexOf(term) > -1)
        }
    },
    created () {
        this.meta.title = `Explore - ESSE`
    },
    methods: {
        openServiceCategoriesModal(serviceType) {
            if (serviceType.service_categories.length === 0) {
                this.$router.push({name: 'search', params: {type: serviceType.slug}})
                return;
            }
            this.selectedServiceType = serviceType;
            this.$modal.show('serviceCategoriesModal')
        },
        openServicesModal(serviceCategory) {
            if (serviceCategory.services.length === 0) {
                this.$router.push({name: 'search', params: {type: this.selectedServiceType.slug, service_category: serviceCategory.slug}})
                return;
            }
            this.selectedServiceCategory = serviceCategory;
            this.$modal.show('servicesModal')
        },
        search () {
            this.$router.push({
                name: 'search',
                params: {
                    term: this.filters.term,
                }
            })
        },
    }
}
