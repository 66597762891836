import Vue from 'vue';
import VueRouter from 'vue-router'

import App from '~/pages/App';

import Auth from '~/pages/Auth.vue'
import Error from '~/pages/Error.vue'
import AccountLayout from '~/pages/Desktop/AccountLayout'
import FavoritesMobile from '~/pages/Mobile/FavoritesMobile'
import BookingHistoryMobile from '~/pages/Mobile/BookingHistoryMobile'

import Home from '~/pages/Desktop/Home.vue'
import ExploreMobile from '~/pages/Mobile/ExploreMobile.vue'

import Search from '~/pages/Desktop/Search.vue'
import SearchMobile from '~/pages/Mobile/SearchMobile.vue'

import BusinessDetails from '~/pages/Desktop/BusinessDetails.vue'
import BusinessDetailsMobile from '~/pages/Mobile/BusinessDetailsMobile.vue'

import Booking from '~/pages/Desktop/Booking.vue'
import BookingMobile from '~/pages/Mobile/BookingMobile.vue'

import AccountData from '~/pages/Account/AccountData.vue';
import Notifications from '~/pages/Account/Notifications.vue';
import PaymentHistory from '~/pages/Account/PaymentHistory.vue';
import CardsList from '~/pages/Account/CardsList.vue';
import BookingHistory from '~/pages/Account/BookingHistory.vue';
import MyReviews from '~/pages/Account/MyReviews.vue';
import Favorites from '~/pages/Account/Favorites.vue';
import Languages from '~/pages/Account/Languages.vue';

import InfoPage from '~/pages/InfoPage.vue';
import ContactUs from '~/pages/ContactUs';
import AccountLayoutMobile from '@/pages/Mobile/AccountLayoutMobile'
// import AboutUs from '~/pages/AboutUs';

Vue.use(VueRouter);


let router = new VueRouter({
    mode: 'history',
    linkActiveClass: 'active',
    base: '/',
    scrollBehavior(to, from, savedPosition) {
        // always scroll to top
        return { top: 0 }
    },
    routes: [
        {
            path: '',
            component: App,
            children: [
                {path: '/error', name: 'error', component: Error, meta: {rule: 'isPublic'}},

                {path: '', name: 'home', components: { default: Home, mobile: ExploreMobile }, meta: {title: 'Home', rule: 'isPublic'}},
                {path: '/search/:type?', name: 'search', components: { default: Search, mobile: SearchMobile }, meta: {title: 'Search', rule: 'isPublic'}},

                {path: '/business/:businessSlug', name: 'business.details', components: { default: BusinessDetails, mobile: BusinessDetailsMobile }, meta: {title: 'Business Details', rule: 'isPublic'}},

                // {path: '/contact', name: 'contact', component: Contact, meta: {title: 'Contact', rule: 'isPublic'}},
                {path: '/cookies-policy', name: 'page.cookies-policy', components: { default: InfoPage, mobile: InfoPage }, meta: {title: 'Cookies Policy', rule: 'isPublic', code: 'cookies'}},
                {path: '/privacy-policy', name: 'page.privacy-policy', components: { default: InfoPage, mobile: InfoPage }, meta: {title: 'Privacy Policy', rule: 'isPublic', code: 'privacy'}},
                {path: '/terms-and-conditions', name: 'page.terms', components: { default: InfoPage, mobile: InfoPage }, meta: {title: 'Terms and conditions', rule: 'isPublic', code: 'terms'}},
                {path: '/contact-us', name: 'contact-us', components: { default: ContactUs, mobile: ContactUs }, meta: {rule: 'isPublic'}},
                {path: '/about-us', name: 'page.about-us', components: { default: InfoPage, mobile: InfoPage }, meta: {title: 'About us', rule: 'isPublic', code: 'about_us'}},

                {path: '/booking/:businessSlug', name: 'booking', components: { default: Booking, mobile: BookingMobile }, meta: {title: 'Booking', rule: 'isLogged', noMenu: true}},

                {
                    path: '/account',
                    name: 'account',
                    components: { default: AccountLayout, mobile: AccountLayoutMobile },
                    children: [
                        {path: 'info', name: 'account.info', component: AccountData, meta: { title: 'Personal data', rule: 'isLogged', noMenu: true}},
                        {path: 'notifications', name: 'account.notifications', component: Notifications, meta: { title: 'Notifications', rule: 'isLogged', noMenu: true}},
                        {path: 'payment-history', name: 'account.payment-history', component: PaymentHistory, meta: { title: 'Payment History', rule: 'isLogged', noMenu: true}},
                        {path: 'payment-cards-list', name: 'account.cards-list', component: CardsList, meta: { title: 'Payment Cards', rule: 'isLogged', noMenu: true}},
                        {path: 'booking-history', name: 'account.booking-history', component: BookingHistory, meta: { title: 'Booking History', rule: 'isLogged', noMenu: true}},
                        {path: 'reviews', name: 'account.reviews', component: MyReviews, meta: { title: 'My Reviews', rule: 'isLogged', noMenu: true}},
                        {path: 'favorites', name: 'account.favorites', component: Favorites, meta: { title: 'My Favorites', rule: 'isLogged', noMenu: true}},
                        {path: 'languages', name: 'account.languages', component: Languages, meta: { title: 'Languages', rule: 'isLogged', noMenu: true}},
                    ],
                    meta: {
                        noMenu: true,
                        rule: 'isLogged',
                        routeKey: 'AccountLayout'
                    }
                },

                {path: 'booking-history', name: 'booking-history', components: { default: BookingHistoryMobile, mobile: BookingHistoryMobile }, meta: { title: 'Booking History', rule: 'isLogged', noMenu: true}},
                {path: 'favorites', name: 'favorites', components: { default: FavoritesMobile, mobile: FavoritesMobile }, meta: { title: 'My Favorites', rule: 'isLogged', noMenu: true}},

            ],
            meta: {
                rule: 'isPublic',
            }
        },
        {path: '/login', name: 'login', component: Auth, meta: {rule: 'isPublic'}},
        {path: '/register', name: 'register', component: Auth, meta: {rule: 'isPublic'}},
    ]
});

router.beforeEach((to, from, next) => {
    if (to.meta) {
        // store.commit('setMeta', to.meta);
        // if (to.meta.title) {
        //     document.title = to.meta.title + ' | Esse'
        // }else{
        //     document.title =  'Esse'
        // }
    }

    next();
});

export {router}

export function createRouter() {
    return router
}
