import { render, staticRenderFns } from "./ExploreMobile.vue?vue&type=template&id=218c1831&"
import script from "./ExploreMobile.vue?vue&type=script&lang=js&"
export * from "./ExploreMobile.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PopularBusinesses: require('/Users/tudorserban/Projects/esse-fe/components/PopularBusinesses.vue').default,SpecialOffers: require('/Users/tudorserban/Projects/esse-fe/components/SpecialOffers.vue').default,ProfessionalsList: require('/Users/tudorserban/Projects/esse-fe/components/ProfessionalsList.vue').default})
