//
//
//
//
//
//
//
//


import Favorites from '~/pages/Account/Favorites'
import { metaMixin } from '@/mixins/metaMixin'

export default {
    name: 'FavoritesMobile',
    components: { Favorites },
    mixins: [metaMixin],
}
