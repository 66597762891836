//
//
//
//
//
//
//
//


import BookingHistory from '~/pages/Account/BookingHistory'

export default {
    name: 'BookingHistoryMobile',
    components: { BookingHistory },
    head: {
        title: 'My Bookings history - ESSE',
    },
}
