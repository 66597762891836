import Vue from 'vue'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import duration from 'dayjs/plugin/duration'
import isBetween from 'dayjs/plugin/isBetween'
dayjs.extend(customParseFormat)
dayjs.extend(duration)
dayjs.extend(isBetween)

export default () => {

    Vue.filter('moment', (value, format) => dayjs(value).format(format))
    Vue.filter('momentTime', (value, format) => dayjs(value, 'H:mm:ss').format(format))
    Vue.filter('momentAgo', (value) => dayjs(value).fromNow())
    Vue.filter('percentage', (value) => `${parseFloat((value * 100).toFixed(1))}%`)

    Vue.filter('toPrice', (value, currency) => {
        if (typeof currency === 'undefined') {
            currency = ' CAD'
        }
        if (typeof value === 'undefined' || value === null) {
            return '-'
        }
        if (!(`${value}`).includes('e')) {
            value = +(`${Math.round(`${value}e+${2}`)}e-${2}`)
        } else {
            const arr = (`${value}`).split('e')
            let sig = ''
            if (+arr[1] + 2 > 0) {
                sig = '+'
            }
            value = +(`${Math.round(`${+arr[0]}e${sig}${+arr[1] + 2}`)}e-${2}`)
        }
        value = parseFloat(value).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

        return `${value} ${currency}`
    })

    Vue.filter('timeFormat', (value, format = 'hh:mm A') => {
        if (!value) {
            return '-'
        }
        return dayjs(value, 'HH:mm').format(format)
    })

    Vue.filter('toDuration', (value, format = 'H [h] mm [min]') => {
        return dayjs.duration(value, 'minutes').format(format);
    })

    Vue.filter('truncate', function (value, length) {
        if (value.length < length) {
            return value
        }

        return value.substring(0, length) + '...'
    })

    Vue.filter('ucfirst', function (value) {
        if (!value) {
            return value
        }
        return value.charAt(0).toUpperCase() + value.slice(1)
    })

    Vue.filter('striphtml', function (value) {
        let div = document.createElement('div')
        div.innerHTML = value
        return div.textContent || div.innerText || ''
    })

    Vue.filter('mapObject', function (value, field) {
        if (!value || !value.length) {
            return '-'
        }
        field = field || 'name'
        return value.map(function (item) {
            return item[field]
        }).join(', ')
    })

    Vue.filter('field_length', function (value, limit) {
        let length
        let class_name = ''
        if (!value) {
            length = 0
        } else {
            length = value.length
        }

        if (length > limit) {
            class_name = 'text-danger'
        }

        return '<span class="' + class_name + '">' + length + '/' + limit + ' limit</span>'
    })

    Vue.filter('beautify', (value) => {
        if (!value) {
            return value
        }
        value = value.replace(/[_-]/g, ' ')
        return value.charAt(0).toUpperCase() + value.slice(1)
    })

    const weekdays = ['', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
    Vue.filter('weekdayName', (value) => {
        if (!weekdays[value]) {
            return value
        }
        return weekdays[value]
    })

// Vue.filter('moment', (value, format) => {
//   if (!value) {
//     return '-';
//   }
//   if (typeof value === 'object' && typeof value.date !== 'undefined') {
//     value = value.date;
//   }
//   format = format || 'DD MMM YYYY, HH:mm';
//   let abbr = '';
//   if (format.indexOf('zz') > -1) {
//     abbr = 'UTC';
//   }
//   return `${moment(value).format(format)} ${abbr}`;
// });
//
// Vue.filter('momentLocal', (value, format) => {
//   if (!value) {
//     return '-';
//   }
//   if (typeof value === 'object' && typeof value.date !== 'undefined') {
//     value = value.date;
//   }
//   format = format || 'DD MMM YYYY, HH:mm zz';
//   let abbr = '';
//   if (format.indexOf('zz') > -1) {
//     abbr = moment.tz(moment.tz.guess()).format('zz');
//   }
//   return `${moment.utc(value).local().format(format)} ${abbr}`;
// });

// Vue.filter('toPrice', (value, currency) => {
//   if (typeof currency === 'undefined') {
//     currency = '$';
//   }
//   if (typeof value === 'undefined' || value === null) {
//     return '-';
//   }
//   return `${currency}${parseFloat(value).toFixed(2)}`;
// });

    Vue.filter('pad', (value, size) => {
        value = value.toString()
        while (value.length < size) value = '0' + value
        return value
    })

    Vue.filter('addHttp', (value) => {
        if (value.indexOf('http') > -1) {
            return value
        }
        return `https://${value}`
    })

    Vue.filter('firstLetterPrimary', (value) => {
        return value.split(' ').map(part => `<span class="fl-primary">${part}</span>`).join(' ');
    })

    function calcCrow(lat1, lon1, lat2, lon2)
    {
        const R = 6371;
        const dLat = toRad(lat2-lat1);
        const dLon = toRad(lon2-lon1);
        lat1 = toRad(lat1);
        lat2 = toRad(lat2);

        const a = Math.sin(dLat/2) * Math.sin(dLat/2) +
          Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
        return R * c;
    }

    function toRad(Value)
    {
        return Value * Math.PI / 180;
    }

    Vue.filter('getDistance', (lat, long, lat1, long1) => {
        if (!lat || !long || !lat1 || !long1) {
            return null
        }
        let distance = calcCrow(lat, long, lat1, long1)
        return distance.toFixed(1)
    })

};
