//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import {mapState} from 'vuex'
// import {FadeTransition} from 'vue2-transitions'

export default {
    name: 'AccountLayoutMobile',
    transitions: {
        name: 'home',
        mode: 'out-in'
    },
    head: {
        title: 'My Account - ESSE',
        meta: [
            {
                hid: 'description',
                name: 'description',
                content: 'User account'
            }
        ],
    },
    // components: { FadeTransition },
    data: function () {
        return {
            version: process.env.VERSION
        }
    },
    computed: {
        ...mapState({
            user: state => state.user,
            meta: state => state.meta,
            settings: state => state.settings,
        })
    },
    methods: {
        logout() {
            this.$dialog.confirm(this.$t('alert_logout'), {
                okText: this.$t('yes'),
                cancelText: this.$t('no')
            })
                .then(() => {
                    this.$auth.destroyToken();
                    this.$acl.change('public');
                    this.$store.commit('logout');
                    this.$router.push({ name: 'home' })
                });
        },
    }
}
